import { useNavigate, useParams } from "react-router";
import { crm } from "../../store/cabinetApi/crm";
import { skipToken } from "@reduxjs/toolkit/query";
import { PAGE_URL } from "../../constants";
import { selectDenormalizedData } from "../../store/crm/selectors";
import { ContactOrgDenorm } from "../../store";
import { Company } from "../../store/cabinetApi/generated/crm";
import CRMCompanyView from "./CRMCompany";
import useContactsList from "../../components/CRM/CRMContacts/contactsListHook";


type RouteParams = { orgId: string; };


const CRMCompanyContainer = () => {
  const params = useParams<RouteParams>();
  const orgId = params.orgId ? Number(params.orgId) : undefined;

  const { data: org, isLoading: loadingOrg } = crm.useAppCrmCompaniesRetrieveQuery(orgId ?? skipToken);
  const { data: catData } = crm.useAppCrmCompanyCategoriesListQuery({ limit: 1000 });
  const { data: primaryContact } = crm.useAppCrmContactsRetrieveQuery(org?.primary_contact ?? skipToken);

  const contactsListData = useContactsList({
    pageSize: orgId != null ? 20 : 0,
    defaultFilter: { items: [{
      field: 'company', operator: 'equals', value: orgId
    }] },
  });

  const [updateOrganization] = crm.useAppCrmCompaniesPartialUpdateMutation();

  const catList = catData?.data || [];

  const orgCategory = catList.find(c => c.id === org?.category);

  const organization = selectDenormalizedData(
    {data: org},
    { 
      ...(primaryContact && { primary_contact: primaryContact }),
      ...(orgCategory && { category: orgCategory }),
    }
  ) as ContactOrgDenorm | undefined;

  const navigate = useNavigate();

  const handleNavigateToCompanies = () => (
    navigate(PAGE_URL.CRM_COMPANIES)
  );

  const handleUpdateCompanyField = (info: Partial<Company>) => {
    if (org?.id) {
      updateOrganization({ id: org.id, patchedCompany: info });
    }
  };

  return (
    <CRMCompanyView 
      organization={organization || null}
      contactsListData={contactsListData}
      onNavigateToCompanies={handleNavigateToCompanies}
      categoryOptions={catList}
      onSaveBasicInfo={handleUpdateCompanyField}
      loading={loadingOrg}
    />
  );
};


export default CRMCompanyContainer;
